<template>
  <div id="game-right-area">
    <GameInfo/>

    <b-container class="p-0 m-0">
      <b-tabs align="center" style="width:100%;display:inline;" variant="success">
        <b-tab active title="메인" @click="onCateChange(1)"></b-tab>
        <b-tab v-if="game_datas.sports_id == 1" title="전반전" @click="onCateChange(2)"></b-tab>
        <b-tab v-else-if="game_datas.sports_id == 16" title="5이닝" @click="onCateChange(2)"></b-tab>
        <b-tab v-else-if="game_datas.sports_id == 18" title="1쿼터" @click="onCateChange(2)"></b-tab>
        <b-tab v-else-if="game_datas.sports_id == 91" title="1세트" @click="onCateChange(2)"></b-tab>
        <b-tab v-else-if="game_datas.sports_id != 162" title="스페셜" @click="onCateChange(2)"></b-tab>
      </b-tabs>
    </b-container>

    <b-container class="p-0 m-0" v-show="category == 2">
      <b-row v-for="(item, i) in datas" :key="i">
        <b-col>
          <b-row class="p-0 m-0">
            <b-col class="rate_title" v-if="item.odds_type == 'Half Winners'">전반전 승무패</b-col>
            <b-col class="rate_title" v-if="item.odds_type == '1st Half Asian Handicap'">전반전 핸디캡</b-col>
            <b-col class="rate_title" v-if="item.odds_type == '1st Half Over/Under'">전반전 오버언더</b-col>
            <b-col class="rate_title" v-if="item.odds_type == '5 Innings Handicap'">5이닝 핸디캡</b-col>
            <b-col class="rate_title" v-if="item.odds_type == '5 Innings Over/Under'">5이닝 오버언더</b-col>
            <b-col class="rate_title" v-if="item.odds_type == '1st Quarter Handicap'">1쿼터 핸디캡</b-col>
            <b-col class="rate_title" v-if="item.odds_type == '1st Quarter Over/Under'">1쿼터 오버언더</b-col>
            <b-col class="rate_title" v-if="item.odds_type == '1st Set WinLose'">1세트 승패</b-col>
            <b-col class="rate_title" v-if="item.odds_type == '1st Set Over/Under'">1세트 오버언더</b-col>
            <b-col class="rate_title" v-if="item.odds_type == '1st Over/Under'">1 Period 오버언더</b-col>
            <b-col class="rate_title" v-if="item.odds_type == '1st Handicap'">1 Period 핸디캡</b-col>
          </b-row>
          
          <!-- 축구 전반전 승무패 -->
          <b-row class="p-0 m-0" v-if="item.odds_type == 'Half Winners' && item.sports_id == 1">
            <b-col class="col-4 m-0 odds-rate coulum" style="padding:0.1rem;" v-for="(games,j) in item.datas" :key="j">
              <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                <span v-if="games.name == '1' || games.header == '1'" class="odds-name winners">{{game_datas.home_ko}}</span>
                <span v-else-if="games.name == 'Draw' || games.header == 'Tie'" class="odds-name">무승부</span>
                <span v-else-if="games.name == '2' || games.header == '2'" class="odds-name winners">{{game_datas.away_ko}}</span>
                <span class="odds-value">{{games.odds}}</span>
              </b-button>
            </b-col>
          </b-row>

          <!-- 축구 전반전 핸디캡 -->
          <b-row class="p-0 m-0" v-else-if="item.odds_type == '1st Half Asian Handicap' && item.sports_id == 1">
            <b-col class='col-6'>
              <b-row>
                <template v-for="(games, j) in item.datas">
                  <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '1'" :key="j">
                    <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                      <span class="odds-header winlose">{{game_datas.home_ko}}</span>
                      <span class="odds-name ml-h tc-gold">( {{games.handicap}} )</span>
                      <span class="odds-value">{{games.odds}}</span>
                    </b-button>
                  </b-col>
                </template>
              </b-row>
            </b-col>
            <b-col class='col-6'>
              <b-row>
                <template v-for="(games, j) in item.datas">
                  <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '2'" :key="j">
                    <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                      <span class="odds-header winlose">{{game_datas.away_ko}}</span>
                      <span class="odds-name ml-h tc-gold">( {{games.handicap}} )</span>
                      <span class="odds-value">{{games.odds}}</span>
                    </b-button>
                  </b-col>
                </template>
              </b-row>
            </b-col>
          </b-row>

          <!-- 축구 전반전 오버언더 -->
          <b-row class="p-0 m-0" v-else-if="item.odds_type == '1st Half Over/Under' && item.sports_id == 1">
            <b-col class='col-6'>
              <b-row>
                <template v-for="(games, j) in item.datas">
                <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == 'Over'" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                    <span class="odds-header">[ {{games.header}} ]</span>
                    <span class="odds-name ml-h tc-gold">{{games.name}}</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
                </template>
              </b-row>
            </b-col>
            <b-col class='col-6'>
              <b-row>
                <template v-for="(games, j) in item.datas">
                <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == 'Under'" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                    <span class="odds-header">[ {{games.header}} ]</span>
                    <span class="odds-name ml-h tc-gold">{{games.name}}</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
                </template>
              </b-row>
            </b-col>
          </b-row>

          <!-- 야구 5이닝 핸디캡 -->
          <b-row class="p-0 m-0" v-else-if="(item.odds_type == '5 Innings Handicap' && item.sports_id == 16)">
            <!-- 홈 -->
            <b-col class='col-6 p-0 m-0'>
              <b-row class='p-0 m-0'>
                <template v-for="(games, j) in item.datas">
                  <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '2'" :key="j">
                    <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                      <span class="odds-header winlose">{{game_datas.away_ko}}</span>
                      <span class="odds-name ml-h tc-gold">( {{games.handicap}} )</span>
                      <span class="odds-value">{{games.odds}}</span>
                    </b-button>
                  </b-col>
                </template>
              </b-row>
            </b-col>
            <!-- 원정 -->
            <b-col class='col-6 p-0 m-0'>
              <b-row class='p-0 m-0'>
                <template v-for="(games, j) in item.datas">
                  <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '1'" :key="j">
                    <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                      <span class="odds-header winlose">{{game_datas.home_ko}}</span>
                      <span class="odds-name ml-h tc-gold">( {{games.handicap}} )</span>
                      <span class="odds-value">{{games.odds}}</span>
                    </b-button>
                  </b-col>
                </template>
              </b-row>
            </b-col>
          </b-row>

          <!-- 야구 5이닝 오버/언더 -->
          <b-row class="p-0 m-0" v-else-if=" item.odds_type == '5 Innings Over/Under' && item.sports_id == 16 ">
            <b-col class='col-6'>
              <b-row>
                <template v-for="(games, j) in item.datas">
                <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == 'Over'" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                    <span class="odds-header">[ {{games.header}} ]</span>
                    <span class="odds-name ml-h tc-gold">{{games.name}}</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
                </template>
              </b-row>
            </b-col>
            <b-col class='col-6'>
              <b-row>
                <template v-for="(games, j) in item.datas">
                <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == 'Under'" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                    <span class="odds-header">[ {{games.header}} ]</span>
                    <span class="odds-name ml-h tc-gold">{{games.name}}</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
                </template>
              </b-row>
            </b-col>
          </b-row>

          <!-- 야구 5이닝, 농구 1쿼터 핸디캡 -->
          <b-row class="p-0 m-0" v-else-if="(item.odds_type == '5 Innings Handicap' && item.sports_id == 16) || (item.odds_type == '1st Handicap' && item.sports_id == 17) || (item.odds_type == '1st Quarter Handicap' && item.sports_id == 18)">
            <!-- 홈 -->
            <b-col class='col-6 p-0 m-0'>
              <b-row class='p-0 m-0'>
                <template v-for="(games, j) in item.datas">
                  <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '2'" :key="j">
                    <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                      <span class="odds-header winlose">{{game_datas.away_ko}}</span>
                      <span class="odds-name ml-h tc-gold">( {{games.handicap}} )</span>
                      <span class="odds-value">{{games.odds}}</span>
                    </b-button>
                  </b-col>
                </template>
              </b-row>
            </b-col>
            <!-- 원정 -->
            <b-col class='col-6 p-0 m-0'>
              <b-row class='p-0 m-0'>
                <template v-for="(games, j) in item.datas">
                  <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '1'" :key="j">
                    <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                      <span class="odds-header winlose">{{game_datas.home_ko}}</span>
                      <span class="odds-name ml-h tc-gold">( {{games.handicap}} )</span>
                      <span class="odds-value">{{games.odds}}</span>
                    </b-button>
                  </b-col>
                </template>
              </b-row>
            </b-col>
          </b-row>

          <!-- 아이스하키 1P, 농구 1쿼터, 배구 1세트 오버/언더 -->
          <b-row class="p-0 m-0" v-else-if="(item.odds_type == '1st Over/Under' && item.sports_id == 17) || (item.odds_type == '1st Quarter Over/Under' && item.sports_id == 18) || (item.odds_type == '1st Set Over/Under' && item.sports_id == 91)">
            <b-col class='col-6'>
              <b-row>
                <template v-for="(games, j) in item.datas">
                <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == 'Over'" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                    <span class="odds-header">[ {{games.header}} ]</span>
                    <span class="odds-name ml-h tc-gold">{{games.handicap}}</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
                </template>
              </b-row>
            </b-col>
            <b-col class='col-6'>
              <b-row>
                <template v-for="(games, j) in item.datas">
                <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == 'Under'" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                    <span class="odds-header">[ {{games.header}} ]</span>
                    <span class="odds-name ml-h tc-gold">{{games.handicap}}</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
                </template>
              </b-row>
            </b-col>
          </b-row>

          <!-- 배구 1세트 승패 -->
          <b-row class="p-0 m-0" v-if="item.odds_type == '1st Set WinLose'">
            <!-- 홈 -->
            <b-col class="col-6 m-0 p-0">
              <b-row class="p-0 m-0">
                <b-col class="col-12 m-0 odds-rate coulum" style="padding:0.1rem;" v-for="(games,j) in item.datas" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button" v-if="item.sports_id == 91 && games.header == '1'">
                    <span class="odds-name winlose">{{game_datas.home_ko}}</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <!-- 원정 -->
            <b-col class="col-6 m-0 p-0">
              <b-row class="p-0 m-0">
                <b-col class="col-12 m-0 odds-rate coulum" style="padding:0.1rem;" v-for="(games,j) in item.datas" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button" v-if="item.sports_id == 91 && games.header == '2'">
                    <span class="odds-name winlose">{{game_datas.away_ko}}</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="p-0 m-0" v-show="category == 1">
    <b-row v-for="(item, i) in datas" :key="i">
      <b-col>

        <!-- 타이틀 --> 
        <b-row class="p-0 m-0">
          <b-col class="rate_title mt-1" v-if="item.odds_type == 'WinLose'">승/패 <span v-if="item.sports_id == '16' || item.sports_id == '17' || item.sports_id == '18'">( 연장전 포함 )</span></b-col>
          <b-col class="rate_title mt-1" v-if="item.odds_type == 'Winners'">승무패</b-col>
          <b-col class="rate_title mt-1" v-if="item.odds_type == 'Handicap'">핸디캡 <span v-if="item.sports_id == '16' || item.sports_id == '17' || item.sports_id == '18'">( 연장전 포함 )</span></b-col>
          <b-col class="rate_title mt-1" v-if="item.odds_type == 'Handicap - Point'">핸디캡 - Point</b-col>
          <b-col class="rate_title mt-1" v-if="item.odds_type == 'Over/Under'">오버언더 <span v-if="item.sports_id == '16' || item.sports_id == '17' || item.sports_id == '18'">( 연장전 포함 )</span></b-col>
          <b-col class="rate_title mt-1" v-if="item.odds_type == 'Over/Under - Point'">오버언더 - Point</b-col>
        </b-row>

        <!-- 축구 승무패 -->
        <b-row class="p-0 m-0" v-if="item.odds_type == 'Winners' && item.sports_id == 1">
          <b-col class="col-4 m-0 odds-rate coulum" style="padding:0.1rem;" v-for="(games,j) in item.datas" :key="j">
            <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
              <span v-if="games.name == '1' || games.header == '1'" class="odds-name winners">{{game_datas.home_ko}}</span>
              <span v-else-if="games.name == 'Draw' || games.header == 'Tie'" class="odds-name">무승부</span>
              <span v-else-if="games.name == '2' || games.header == '2'" class="odds-name winners">{{game_datas.away_ko}}</span>
              <span class="odds-value">{{games.odds}}</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- 아이스하키 승무패 -->
        <b-row class="p-0 m-0" v-if="item.odds_type == 'Winners' && item.sports_id == 17">
          <b-col class="col-4 m-0 odds-rate coulum" style="padding:0.1rem;">
            <b-button v-on:click="goCart(item, 2, $event)" :class="'bet-id-'+item.datas[2].id" class="button">
              <span class="odds-name winners">{{game_datas.away_ko}}</span>
              <span class="odds-value">{{item.datas[2].odds}}</span>
            </b-button>
          </b-col>
          <b-col class="col-4 m-0 odds-rate coulum" style="padding:0.1rem;">
            <b-button v-on:click="goCart(item, 1, $event)" :class="'bet-id-'+item.datas[1].id" class="button">
              <span class="odds-name">무승부</span>
              <span class="odds-value">{{item.datas[1].odds}}</span>
            </b-button>
          </b-col>
          <b-col class="col-4 m-0 odds-rate coulum" style="padding:0.1rem;">
            <b-button v-on:click="goCart(item, 0, $event)" :class="'bet-id-'+item.datas[0].id" class="button">
              <span class="odds-name winners">{{game_datas.home_ko}}</span>
              <span class="odds-value">{{item.datas[0].odds}}</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- 야구, 농구 : 홈원정 변경 || 배구, MMA 승패 -->
        <b-row class="p-0 m-0" v-if="item.odds_type == 'WinLose'">
          <!-- 홈 -->
          <b-col class="col-6 m-0 p-0">
            <b-row class="p-0 m-0">
              <b-col class="col-12 m-0 odds-rate coulum" style="padding:0.1rem;" v-for="(games,j) in item.datas" :key="j">
                <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button" v-if="(item.sports_id == 91 && games.header == '1') || (item.sports_id == 162 && games.name == '1')">
                  <span class="odds-name winlose">{{game_datas.home_ko}}</span>
                  <span class="odds-value">{{games.odds}}</span>
                </b-button>
                <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button" v-else-if="( item.sports_id == 16 || item.sports_id == 17 || item.sports_id == 18 ) && games.header == '2'">
                  <span class="odds-name winlose">{{game_datas.away_ko}}</span>
                  <span class="odds-value">{{games.odds}}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!-- 원정 -->
          <b-col class="col-6 m-0 p-0">
            <b-row class="p-0 m-0">
              <b-col class="col-12 m-0 odds-rate coulum" style="padding:0.1rem;" v-for="(games,j) in item.datas" :key="j">
                <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button" v-if="( item.sports_id == 91 && games.header == '2') || (item.sports_id == 162 && games.name == '1')">
                  <span class="odds-name winlose">{{game_datas.away_ko}}</span>
                  <span class="odds-value">{{games.odds}}</span>
                </b-button>
                <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button" v-else-if="( item.sports_id == 16 || item.sports_id == 17 || item.sports_id == 18 ) && games.header == '1'">
                  <span class="odds-name winlose">{{game_datas.home_ko}}</span>
                  <span class="odds-value">{{games.odds}}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- 축구, 배구 핸디캡 -->
        <b-row class="p-0 m-0" v-else-if="item.odds_type == 'Handicap' && ( item.sports_id == 1 || item.sports_id == 91  )">
          <b-col class='col-6'>
            <b-row>
              <template v-for="(games, j) in item.datas">
                <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '1'" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                    <span class="odds-header winlose">{{game_datas.home_ko}}</span>
                    <span class="odds-name ml-h tc-gold">( {{games.handicap}} )</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
              </template>
            </b-row>
          </b-col>
          <b-col class='col-6'>
            <b-row>
              <template v-for="(games, j) in item.datas">
                <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '2'" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                    <span class="odds-header winlose">{{game_datas.away_ko}}</span>
                    <span class="odds-name ml-h tc-gold">( {{games.handicap}} )</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
              </template>
            </b-row>
          </b-col>
        </b-row>

        <!-- 야구, 농구, 아이스하키 핸디캡 : 홈 원정 변경 -->
        <b-row class="p-0 m-0" v-else-if="item.odds_type == 'Handicap' && ( item.sports_id == 16 || item.sports_id == 17 || item.sports_id == 18 )">
          <!-- 홈 -->
          <b-col class='col-6 p-0 m-0'>
            <b-row class='p-0 m-0'>
              <template v-for="(games, j) in item.datas">
                <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '2'" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                    <span class="odds-header winlose">{{game_datas.away_ko}}</span>
                    <span class="odds-name ml-h tc-gold">( {{games.handicap}} )</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
              </template>
            </b-row>
          </b-col>
          <!-- 원정 -->
          <b-col class='col-6 p-0 m-0'>
            <b-row class='p-0 m-0'>
              <template v-for="(games, j) in item.datas">
                <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '1'" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                    <span class="odds-header winlose">{{game_datas.home_ko}}</span>
                    <span class="odds-name ml-h tc-gold">( {{games.handicap}} )</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
              </template>
            </b-row>
          </b-col>
        </b-row>

        <!-- 배구 핸디캡 Point-->
        <b-row class="p-0 m-0" v-else-if="item.odds_type == 'Handicap - Point'">
          <b-col class='col-6'>
            <b-row>
              <template v-for="(games, j) in item.datas">
                <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '1'" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                    <span class="odds-header winlose">{{game_datas.home_ko}}</span>
                    <span class="odds-name ml-h tc-gold">( {{games.name}} )</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
              </template>
            </b-row>
          </b-col>
          <b-col class='col-6'>
            <b-row>
              <template v-for="(games, j) in item.datas">
                <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '2'" :key="j">
                  <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                    <span class="odds-header winlose">{{game_datas.away_ko}}</span>
                    <span class="odds-name ml-h tc-gold">( {{games.name}} )</span>
                    <span class="odds-value">{{games.odds}}</span>
                  </b-button>
                </b-col>
              </template>
            </b-row>
          </b-col>
        </b-row>

        <!-- 축구, 야구, 아이스하키, 농구 오버언더 -->
        <b-row class="p-0 m-0" v-else-if="( item.odds_type == 'Over/Under' ) && ( item.sports_id == 1 || item.sports_id == 16 || item.sports_id == 17 || item.sports_id == 18)">
          <b-col class='col-6'>
            <b-row>
              <template v-for="(games, j) in item.datas">
              <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == 'Over'" :key="j">
                <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                  <span class="odds-header">[ {{games.header}} ]</span>
                  <span class="odds-name ml-h tc-gold">{{games.name}}</span>
                  <span class="odds-value">{{games.odds}}</span>
                </b-button>
              </b-col>
              </template>
            </b-row>
          </b-col>
          <b-col class='col-6'>
            <b-row>
              <template v-for="(games, j) in item.datas">
              <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == 'Under'" :key="j">
                <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                  <span class="odds-header">[ {{games.header}} ]</span>
                  <span class="odds-name ml-h tc-gold">{{games.name}}</span>
                  <span class="odds-value">{{games.odds}}</span>
                </b-button>
              </b-col>
              </template>
            </b-row>
          </b-col>
        </b-row>

        <!-- 배구 오버언더 -->
        <b-row class="p-0 m-0" v-else-if="item.odds_type == 'Over/Under' || item.odds_type == 'Over/Under - Point' && ( item.sports_id == 91 )">
          <b-col class='col-6'>
            <b-row>
              <template v-for="(games, j) in item.datas">
              <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '1'" :key="j">
                <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                  <span class="odds-header">[ {{games.name}} ]</span>
                  <span class="odds-name ml-h tc-gold">{{games.handicap}}</span>
                  <span class="odds-value">{{games.odds}}</span>
                </b-button>
              </b-col>
              </template>
            </b-row>
          </b-col>
          <b-col class='col-6'>
            <b-row>
              <template v-for="(games, j) in item.datas">
              <b-col class="m-0 odds-rate coulum" style="padding:0.1rem;" v-if="games.header == '2'" :key="j">
                <b-button v-on:click="goCart(item, j, $event)" :class="'bet-id-'+games.id" class="button">
                  <span class="odds-header">[ {{games.name}} ]</span>
                  <span class="odds-name ml-h tc-gold">{{games.handicap}}</span>
                  <span class="odds-value">{{games.odds}}</span>
                </b-button>
              </b-col>
              </template>
            </b-row>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
    </b-container>
  </div>
</template>

<script>

import { BRow, BCol, BImg, BContainer,BButton, BTabs, BTab } from 'bootstrap-vue'
import EventBus from '../../event-bus';
import GameInfo from './GameInfo.vue'

export default {
  components: {
    BRow,BCol,BImg,BContainer,BButton,GameInfo,BTabs,BTab
  },
  data () {
    return {
      title: '',
      datas: [],
      game_datas : [],
      bFlag: false,
      bShow: true,
      category : 1,
      users:[],
    }
  },
  created () {
    EventBus.$on('game_view', (obj)=>{
      console.log("game_view");
      if( obj != null ){
        this.gameViewDetail(obj);
      } else {
        this.datas = [];
        this.game_datas = [];
      }
    });

    EventBus.$on('add_bet', (id)=>
    {
      var bet_id = document.querySelector("#game-right-area .bet-id-"+id);
      if( bet_id != null)
      {
          bet_id.classList.add("selected");
      }
    });

    EventBus.$on('remove_bet', (id)=>
    {
      var bet_id = document.querySelector("#game-right-area .bet-id-"+id);
      if( bet_id != null)
      {
          bet_id.classList.remove("selected");
      }
    });

  },
  updated() {
    this.getBettingDataFromCookie();
  },
  mounted() {
    if( localStorage.getItem("userData") != null){
      this.users = JSON.parse(localStorage.getItem("userData"));
    }
  },
  methods: {
    async onCateChange(c)
    {
        this.category = c;

        // 배당 갱신
        var results2 = await this.$api('http://server.honey-game.com/renew/renew_list/'+this.users.agent_idx, 'get');

        for( var i=0; i<results2.datas.length; i++ ){
          var bet_id = document.querySelector("#game-left-area .bet-id-"+results2.datas[i].rate_id);
          if( bet_id ) {
            bet_id.innerHTML = (Math.round(results2.datas[i].rate_value * 100) / 100).toFixed(2);
          }

          var bet_id2 = document.querySelector("#game-right-area .bet-id-"+results2.datas[i].rate_id+" .odds-value");
          if( bet_id2 ) {
            bet_id2.innerHTML = (Math.round(results2.datas[i].rate_value * 100) / 100).toFixed(2);
          }
        }
    },
    async gameViewDetail(item)
    {
      const at = localStorage.getItem("accessToken");
      const rt = localStorage.getItem("refreshToken");
      this.bFlag = false;

      await this.$api('http://server.honey-game.com/games/odds', 'post', {
        FI: item.id,
        at: at,
        rt: rt
      }).then(datas =>{
        if( datas.result == 2 ){
          this.$router.replace('/pages/miscellaneous/not-authorized');
        } else {
          this.datas = datas;
          this.game_datas = item;

          for(var i=0; i<this.datas.length; i++)
          {
            this.datas[i].datas = JSON.parse(this.datas[i].datas);
          }
          this.bFlag = true;
        }
      })

      // 배당 갱신
      var results2 = await this.$api('http://server.honey-game.com/renew/renew_list/'+this.users.agent_idx, 'get');

      for( var i=0; i<results2.datas.length; i++ ){
        var bet_id = document.querySelector("#game-left-area .bet-id-"+results2.datas[i].rate_id);
        if( bet_id ) {
          bet_id.innerHTML = (Math.round(results2.datas[i].rate_value * 100) / 100).toFixed(2);
        }

        var bet_id2 = document.querySelector("#game-right-area .bet-id-"+results2.datas[i].rate_id+" .odds-value");
        if( bet_id2 ) {
          bet_id2.innerHTML = (Math.round(results2.datas[i].rate_value * 100) / 100).toFixed(2);
        }
      }
    },

    getBettingDataFromCookie(){
      if( localStorage.getItem("betData") != null){
        var betData = JSON.parse(localStorage.getItem("betData"));
        for(var value of betData)
        {
          var bet_id = document.querySelector("#game-right-area .bet-id-"+value.selected.id);
          if( bet_id != null)
          {
            if( !bet_id.classList.contains('selected') ){
              bet_id.classList.add("selected");
            }
          }
        }
      }
    },
    goCart(obj, j, event){
      var selected = obj.datas[j];
      selected.odds = event.target.querySelector(".odds-value").innerHTML;
      //var selected = obj.datas[j];
      var bets = {
        'selected' : selected,
        'game_id' : obj.game_id,
        'league_cc' : this.game_datas.league_cc,
        'league' : this.game_datas.league,
        'league_code' :this.game_datas.league_code,
        'home' : this.game_datas.home_ko,
        'away' : this.game_datas.away_ko,
        'odds_name' : obj.odds_name,
        'odds_type' : obj.odds_type,
        'game_time' : this.game_datas.time,
        'sports_id' : obj.sports_id,
      }

      EventBus.$emit('update_cart', bets);
    }
  },
  beforeDestroy(){
    EventBus.$off();
  },
}

</script>

<style>
#game-right-area {width:calc(100%-10px);background-color:#242a3a;padding:10px;}
#game-right-area .rate_title {line-height:36px;background-color:#233054;color:#ffe588;font-family: "Noto Sans KR",sans-serif;border: 1px solid #111;padding:0px 10px;padding-left:15px;}
#game-right-area .nav-tabs {margin-top:1rem;}
#game-right-area .nav-tabs a {width:150px;}


#game-right-area .coulum { display: flex; flex-direction: column; flex-shrink: 1; flex-basis: auto; flex-wrap: nowrap; justify-content: center;align-items: baseline !important;}
#game-right-area .odds-rate .odds-header {float:left; text-align:left;}
#game-right-area .odds-rate .odds-name {float:left; text-align:left;}
#game-right-area .odds-rate .odds-value {float:right;}

#game-right-area .button {
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  outline: 0;
  position: relative;
  text-decoration: none;
  transition-duration: .3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  color: #fff;
  cursor: pointer;
  padding: 12px 10px;
  width: 100%;
  height: 100%;
  background-color: #13161e !important;
  border:1px solid #383636 !important;
  border-radius:0px;
  font-size:12px;
}
@media (hover: hover){
  #game-right-area .button:hover {  box-shadow: inset 0 0 0 1px #ffe588;transition: all .3s ease; }
}

#game-right-area .button.selected {  box-shadow: inset 0 0 0 1px #ffe588;background-color:black !important;transition: all .3s ease; }

@media (max-width: 475px) {
  .winners {
    max-width:70px !important;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
    display:inline-block;
  }
  .winlose {
    max-width:90px !important;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    display:inline-block;
  }
}
@media (max-width: 575px) {
  .winners {
    max-width:100px !important;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    display:inline-block;
  }
  .winlose {
    max-width:120px !important;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    display:inline-block;
  }
}
</style>
